html {
  scroll-behavior: smooth;
}

#aboutus {
  scroll-margin-top: 100px;
}

.btn-contact {
  color: #fff;
  background-color: #002846;
  border-color: #002846;
  box-shadow: none;
}

.btn-contact:hover {
  color: #fff;
  background-color: #00b7f0;
  border-color: #00b7f0;
}

.img-footer {
  height: 3rem;
  width: auto;
}

.img-whatsapp {
  max-height: 2.5rem;
  width: auto;
}

.img-whatsapp-small {
  max-height: 1.75rem;
  width: auto;
}

.inlineimage-process {
  max-height: 10rem;
  max-height: 10rem;
}

.floating-close {
  position: absolute;
  right: 0;
  top: 0;
}

.truncate {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.btn {
  border-radius: 1000px;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}